import { RouteName, routes } from '@shared/constants/routes'
import { UngatedHomePage } from '@/features/entrypoint/components/UngatedHomePage'
import { createServerSidePropsHandler } from '@/features/shared/serverUtils/ssrHelpers/createServerSidePropsHandler'
import { shouldUseNewAuth } from '@/features/authentication/utils/authentication/utils'
import { fetchCMSDataForSSR } from '@/features/cms/services/CMS/utils'
import { getPageRedirect } from '@/features/shared/serverUtils/ssrHelpers/utils'
import { withoutHeaderLayout } from '@/features/shared/components/Headers/Layouts/withoutHeaderLayout'

export const getServerSideProps = createServerSidePropsHandler(
  RouteName.UNGATED_HOMEPAGE,
  async ({ queryClient, ssrContext, authContext }) => {
    const { isAuthenticated, isOnboarded } = authContext
    if (isAuthenticated) {
      if (shouldUseNewAuth(ssrContext) && !isOnboarded) {
        return getPageRedirect(routes.WELCOME.url)
      }
      return getPageRedirect(routes.GLOBAL_HOMEPAGE.url)
    }

    return fetchCMSDataForSSR({ queryClient, ssrContext, authContext })
  }
)

// header is controlled via CMS
export default withoutHeaderLayout(UngatedHomePage)
