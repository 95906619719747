import styled, { css, useTheme } from 'styled-components'
import { type MembershipPricingData } from '@/features/cms/components/CMS/types'
import { Box, Column, Stack } from '@shipt/design-system-layouts'
import { Body, Headline, Link } from '@shipt/design-system-typography'
import { screenSizes } from '@shipt/design-system-themes'
import { CMSContainer } from '@/features/cms/components/CMS/CMSContainer'
import { MembershipPricingCard } from '@/features/cms/components/CMS/components/MembershipPricing/MembershipPricingCard'
import { ReferenceRenderer } from '@/features/cms/components/CMS/ReferenceRenderer'
import { spacing } from '@/features/shared/theme/tokens'
import { CMSFullBleed } from '@/features/cms/components/CMS/CMSFullBleed'
import {
  renderElement,
  renderElementInverse,
} from '@/features/cms/components/CMS/RichTextRenderer'
import { Carousel } from '@/features/shop/components/Carousel'

/**
 * This component corresponds to the `membership_pricing` content type in the CMS.
 *
 * - **Staging** - https://cms-staging.shipt.com/content-type/pool/membership_pricing
 * - **Production** - https://cms.shipt.com/content-type/pool/membership_pricing
 */
export const MembershipPricing = ({
  content_type_id,
  id,
  data: {
    heading,
    subheading,
    subheading_rich_text,
    legal_text,
    legal_text_cta_link,
    legal_rich_text,
    background_color,
    references,
    carousel_pricing_cards = false,
    pricing_subsections,
    theme,
  },
}: MembershipPricingData) => {
  const designTheme = useTheme()

  const getBackgroundColor = () => {
    if (theme === 'light') {
      return '#F6F5F6'
    } else if (theme === 'dark') {
      return designTheme.color.background.base.brand.primary_heavy
    } else {
      return background_color
    }
  }

  return (
    <SectionWrapper
      id="pricing-plans"
      $background_color={getBackgroundColor()}
      className={background_color || theme ? 'with-background' : undefined}
    >
      <Container id={id} contentTypeId={content_type_id}>
        <HeadingColumn align="center" spacing="sm">
          <Headline
            as="h2"
            size="xl"
            {...(theme === 'dark' && {
              concept: 'accent',
              surface: 'inverse',
              variant: 'secondary',
            })}
          >
            {heading}
          </Headline>
          {subheading && !subheading_rich_text && (
            <Body size="xl">{subheading}</Body>
          )}
          {subheading_rich_text && (
            <div>
              {subheading_rich_text.elements.map(
                theme === 'dark' ? renderElementInverse : renderElement
              )}
            </div>
          )}
        </HeadingColumn>
        <PricingContentColumn $cardsLength={pricing_subsections?.length}>
          {/* this should display if the screen size is mobile and the carousel field is true */}
          {carousel_pricing_cards && (
            <CarouselWrapper>
              <Carousel
                freeMode
                surface={theme === 'dark' ? 'inverse' : 'default'}
                simulateTouch
                pagination={{ el: '.swiper-pagination', clickable: true }}
                slidesOffsetBefore={16}
                slidesOffsetAfter={16}
              >
                {pricing_subsections?.map((section, index) => (
                  <MembershipPricingCard
                    {...section}
                    index={index}
                    key={section.data.membership_type}
                    isCarousel
                  />
                ))}
              </Carousel>
            </CarouselWrapper>
          )}
          {/* this should display if the carousel feature is false or the screen is not mobile */}
          <PricingCardsStack
            spacing="lg"
            direction={{ base: 'column', md: 'row' }}
            isCarousel={carousel_pricing_cards ?? false}
          >
            {pricing_subsections?.map((section, index) => (
              <MembershipPricingCard
                {...section}
                index={index}
                key={section.data.membership_type}
              />
            ))}
          </PricingCardsStack>
          {references && (
            <Box marginBottom="lg">
              <ReferenceRenderer references={references} />
            </Box>
          )}
        </PricingContentColumn>
        {legal_text && !legal_rich_text && (
          <LegalText size="sm" variant="secondary" id="legal_text">
            {legal_text}{' '}
            {legal_text_cta_link && (
              <Link
                size="sm"
                variant="secondary"
                aria-describedby="legal_text"
                href={legal_text_cta_link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {legal_text_cta_link.text}
              </Link>
            )}
          </LegalText>
        )}
        {legal_rich_text && (
          <LegalText as="div">
            {legal_rich_text.elements.map(
              theme === 'dark' ? renderElementInverse : renderElement
            )}
          </LegalText>
        )}
      </Container>
    </SectionWrapper>
  )
}
const CarouselWrapper = styled.div`
  .swiper-pagination-bullet {
    background-color: ${({ theme }) => theme.gray600};
    opacity: 1;
  }

  .swiper-pagination-bullet-active.swiper-pagination-bullet {
    background-color: ${({ theme }) => theme.gray300};
    opacity: 1;
  }

  position: relative;
  margin: 0 -1rem 1rem;

  /* hide the carousel on non-mobile screens */
  @media ${screenSizes.tablet} {
    display: none;
  }
`

const SectionWrapper = styled(CMSFullBleed)<{
  $background_color?: string | null
}>`
  /* Browser uses set margin-top when element is being scrolled into view */
  scroll-margin-top: 84px;

  /* TODO: Remove this className style and the div on which it is applied in src/components/UngatedHomePage/index.tsx when that page gets fully migrated to the CMS */
  .temporary-padding-manager > & {
    padding-top: ${spacing('xl')};
    padding-bottom: ${spacing('xl')};
  }
`

const HeadingColumn = styled(Column)`
  margin-bottom: ${spacing('xl')};
  text-align: center;
`

const PricingContentColumn = styled(Column)<{ $cardsLength: number }>`
  margin: ${spacing(0, 'auto')};
  max-width: ${({ $cardsLength }) => `calc(22.5rem * ${$cardsLength})`};

  @media ${screenSizes.tablet} {
    & > * {
      max-width: 100%;
    }
  }
`

const PricingCardsStack = styled(Stack)<{ isCarousel: boolean }>`
  padding: ${spacing(0)};
  margin: ${spacing(0)};
  margin-bottom: ${spacing('lg')};
  justify-content: center;

  @media ${screenSizes.tablet} {
    margin-bottom: ${spacing('xl')};
  }

  /* Hide this stack on small screens if the carousel feature is active */
  ${({ isCarousel }) =>
    isCarousel &&
    css`
      @media ${screenSizes.max_mobile} {
        display: none;
      }
    `}
`

const LegalText = styled(Body)`
  text-align: center;

  @media ${screenSizes.tablet} {
    max-width: 31.25rem;
    margin: ${spacing(0, 'auto')};
  }
`

const Container = styled(CMSContainer)`
  margin: 0 auto;
`
